declare const viewModel: server.viewmodel.WebformAnonymousViewModel;

jQuery(() => {
  const referrer = document.referrer || "<no referrer>";

  window.CoreApi.Webform.CreateLog({
    webformId: viewModel.webformId,
    url: referrer
  });
});

// Need an empty export to make it a module, or the TS compiler will complain.
export {};